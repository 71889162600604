.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.heading1 {
		display: inline-flex;
		align-items: self-start;
		gap: 6px;
		flex-wrap: wrap;

		i {
			display: inline-flex;
		}
	}

	.stars {
		color: var(--color-stars);
		display: inline-flex;

		@media(max-width:767px) {
			display: block;
		}


		.fa-star {
			&:before {
				width: 20px;
				height: 20px;
				background-size: cover;
				background-image: url(RESOURCE/img/icon_star.png);
				content: "";
				display: inline-block;
				position: relative;
				top: 4px;
			}
		}
	}

	.unit-title-v1 {
		@media(max-width:768px) {
			padding-top: 24px;
		}

		h1 {
			color: var(--color-secondary2);
			font-family: Montserrat, serif !important;
			text-transform: uppercase;
			font-weight: 700;
		}
	}

	.left-col {
		padding: 0px 15px;

		@media(max-width: 1200px) {
			width: 100% !important;
		}

		@media(min-width: 1201px) {
			width: 68% !important;
		}


		@media(min-width: 1500px) {
			width: 70% !important;
		}
	}

	.right-col {

		padding: 0px 15px;

		@media(max-width: 1200px) {
			display: none;
		}

		@media(min-width: 1201px) {
			width: 32% !important;
		}


		@media(min-width: 1500px) {
			width: 30% !important;
		}
	}


	.booking-hint {
		font-size: var(--font-size-md);
		margin-top: -20px;
		padding-bottom: 10px;
	}

	.unit-book-now-v3 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			display: none;
			margin-bottom: 57px;
		}

		&.is-visible {
			@media(min-width:992px) {
				display: block;
			}
		}
	}

	.unit-book-now-v4 {
		@media(min-width:1200px) {
			background-color: var(--color-grey-light);
			padding: 20px 15px;
			position: -webkit-sticky;
			position: sticky;
			z-index: 12;
			top: 180px;
			margin-bottom: 57px;
		}

	}

	.room-beds-bl {
		.box {
			height: 50%;
		}
	}
}